import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

export default function Contact() {

    // header sticky top script start
        const [fix, setFix] = useState(false);
        function setFixed() {
            if (window.scrollY >= 500) {
                setFix(true);
            } else {
                setFix(false);
            }
        }
        window.addEventListener("scroll", setFixed);
    // header sticky top script 

    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);
    const [faq4, setFaq4] = useState(false);
    const [faq5, setFaq5] = useState(false);

    const handleFAQ1 = () => {
        if (faq1 === false) {
            setFaq1(true);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ2 = () => {
        if (faq2 === false) {
            setFaq1(false);
            setFaq2(true);
            setFaq3(false);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ3 = () => {
        if (faq3 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(true);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ4 = () => {
        if (faq4 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(true);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ5 = () => {
        if (faq5 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
            setFaq5(true);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
            setFaq5(false);
        }
    };

    return (
        <>
            
            {/* First Section */}
            <div id="header" className={fix ? "header fixed" : "header"}>
                <div className="header-container">
                    <div className="left-container">
                        {/* Logo */}
                        <img src="/logo.jpg" alt="Logo" className="logo" />
                        {/* <span className="logo-text">Company Logo</span> */}
                        {/* Tagline */}
                        {/* <p className="tagline">Your Tagline Goes Here</p> */}
                    </div>
                    <div className="right-container">
                        {/* Navigation Links */}
                        <Link to="/" className="nav-link">Home</Link>
                        <Link to="/company/about-us" className="nav-link">About</Link>
                        <Link to="/contact-us" className="nav-link">Contact Us</Link>
                        <Link to="#" className="nav-link">+91 91775 85123</Link>
                        <button className="nav-link">
                            Lets Build
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                            </svg> */}
                            <ul>
                                <li className="next"><span></span></li>
                            </ul>
                        </button>
                    </div>
                </div>
            </div>

            <div class="about-us" style={{height: '392px', width: '100%',}}>
                <div 
                    style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        background: "linear-gradient(to top, rgba(0, 0, 0, 0.73), rgba(255, 255, 255, 0)), url(/example-img2.png) center top no-repeat"
                    }}
                >
                    <p
                        style={{
                            fontSize: '50px',
                            fontWeight: 'bold',
                            color: '#fff',
                        }}
                    >Contact Us</p>
                </div>
            </div>


            {/* Ninth Section */}
            <div className="ninth-section">
                <h1 className="section-heading">Heading</h1>
                <p className="section-subheading">Subheading</p>
                <div className="box-container2">
                <div className="faq-list">
                    <div
                        className={
                        faq1 === true ? "faq-box faq-box-active" : "faq-box"
                        }
                        // style={{
                        //   height: faq1 === true ? "100%" : "52px",
                        //   overflow: "hidden",
                        // }}
                    >
                        <button className="top" onClick={handleFAQ1} type="button">
                            <div className="d-flex">
                                {faq1 === true ? (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                    </svg>
                                    </>
                                ) : (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    </>
                                )}
                                <h1>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, quaerat.
                                </h1>
                            </div>
                        </button>
                        <div className="faq-desc">
                            <p>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae adipisci doloremque deserunt! Debitis aliquid mollitia blanditiis alias veritatis, aperiam porro assumenda corrupti! Culpa quisquam omnis veritatis, quasi iusto deserunt ex!
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            faq2 === true ? "faq-box faq-box-active" : "faq-box"
                        }
                        // style={{
                        //   height: faq1 === true ? "100%" : "52px",
                        //   overflow: "hidden",
                        // }}
                    >
                        <button className="top" onClick={handleFAQ2} type="button">
                            <div className="d-flex">
                                {faq2 === true ? (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                    </svg>
                                    </>
                                ) : (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    </>
                                )}
                                <h1>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, quaerat.
                                </h1>
                            </div>
                        </button>
                        <div className="faq-desc">
                            <p>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae adipisci doloremque deserunt! Debitis aliquid mollitia blanditiis alias veritatis, aperiam porro assumenda corrupti! Culpa quisquam omnis veritatis, quasi iusto deserunt ex!
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            faq3 === true ? "faq-box faq-box-active" : "faq-box"
                        }
                        // style={{
                        //   height: faq1 === true ? "100%" : "52px",
                        //   overflow: "hidden",
                        // }}
                    >
                        <button className="top" onClick={handleFAQ3} type="button">
                            <div className="d-flex">
                                {faq3 === true ? (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                    </svg>
                                    </>
                                ) : (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    </>
                                )}
                                <h1>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, quaerat.
                                </h1>
                            </div>
                        </button>
                        <div className="faq-desc">
                            <p>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae adipisci doloremque deserunt! Debitis aliquid mollitia blanditiis alias veritatis, aperiam porro assumenda corrupti! Culpa quisquam omnis veritatis, quasi iusto deserunt ex!
                            </p>
                        </div>
                    </div>
                    <div
                        className={
                            faq4 === true ? "faq-box faq-box-active" : "faq-box"
                        }
                        // style={{
                        //   height: faq1 === true ? "100%" : "52px",
                        //   overflow: "hidden",
                        // }}
                    >
                        <button className="top" onClick={handleFAQ4} type="button">
                            <div className="d-flex">
                                {faq4 === true ? (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                    </svg>
                                    </>
                                ) : (
                                    <>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 448 512"
                                    >
                                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                    </svg>
                                    </>
                                )}
                                <h1>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit. Suscipit, quaerat.
                                </h1>
                            </div>
                        </button>
                        <div className="faq-desc">
                            <p>
                                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Repudiandae adipisci doloremque deserunt! Debitis aliquid mollitia blanditiis alias veritatis, aperiam porro assumenda corrupti! Culpa quisquam omnis veritatis, quasi iusto deserunt ex!
                            </p>
                        </div>
                    </div>
                </div>

                </div>
            </div>


            {/* Tenth Section */}
            <footer>
                <div className="row">
                    <div className="col">
                        {/* <img src="https://via.placeholder.com/200" alt="logo" /> */}
                        <h1>Company Logo</h1>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo cumque facilis exercitationem architecto quis unde saepe deleniti tempora? Alias, voluptas!</p>
                    </div>
                    <div className="col">
                        <h3>Office <div className="underline"><span></span></div> </h3>
                        <p>ITPL Road</p>
                        <p>Whitefield, Banglore</p>
                        <p>Karnataka, Pin 560066, India</p>
                        <p className="email-id">example@example.com</p>
                        <h6>+91 1234567890</h6>
                    </div>
                    <div className="col">
                        <h3>Links <div className="underline"><span></span></div> </h3>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/company/about-us">About Us</Link></li>
                            <li><Link to="/contact-us">Contact</Link></li>
                            <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
                        </ul>
                    </div>
                    <div className="col">
                        <h3>Newsletter <div className="underline"><span></span></div> </h3>
                        <form>
                            <i className="far fa-envelope"></i>
                            <input
                                type="email"
                                placeholder="Enter Your email id"
                                required
                            />
                            <button><i className="fas fa-arrow-right"></i></button>
                        </form>
                        <div className="social-icons">
                            <i className="fab fa-facebook-f"></i>
                            <i className="fab fa-twitter"></i>
                            <i className="fab fa-instagram"></i>
                            <i className="fab fa-youtube"></i>
                        </div>
                    </div>
                </div>
                <hr />
                <p className="copyright">Your Company Name © 2024 - All Rights Reserved.</p>
            </footer>
        </>
    )
}
