import React, { useState, useEffect } from "react";
import Home from "./component/Home";
import AboutUs from "./component/AboutUs";
import PrivacyPolicy from "./component/PrivacyPolicy";
import TermsAndConditions from "./component/TermsAndConditions";
import Contact from "./component/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

export default function App() {

  const [loading, setLoading] = useState(0);
  useEffect(() => {
    // Set the loading bar to 100% to hide it when the component mounts
    setLoading(100);
  }, []);
  const handleLoading = (progress) => {
    setLoading(progress);
  };

  return (
    <>
      <Router>
        <LoadingBar color="#ff1a41" height={4} progress={loading} />
        <Routes>
          <Route
            path="/"
            element={
              <Home
                handleLoading={handleLoading}
                category="BrickX - No.01 construction company"
              />
            }
          />
          <Route
            path="/company/about-us"
            element={
              <AboutUs
                handleLoading={handleLoading}
                category="BrickX - No.01 construction company"
              />
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <TermsAndConditions
                handleLoading={handleLoading}
                category="BrickX - No.01 construction company"
              />
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <PrivacyPolicy
                handleLoading={handleLoading}
                category="BrickX - No.01 construction company"
              />
            }
          />
          <Route
            path="/contact-us"
            element={
              <Contact
                handleLoading={handleLoading}
                category="BrickX - No.01 construction company"
              />
            }
          />

        </Routes>
      </Router>
    </>
  )
}
