import React, { useState, useEffect, useRef } from "react";
import Carousel1 from "react-elastic-carousel";
import Item from "./Item";
import styled from "styled-components";
// import { Button } from "./styles/Button.js";
import { NavLink } from "react-router-dom";
import { FaDiscord, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Home() {

    const [stepActive, setStepActive] = useState("1");
    if (stepActive === "1") {
        setTimeout(() => {
        setStepActive("2");
        }, 4000);
    }
    if (stepActive === "2") {
        setTimeout(() => {
        setStepActive("3");
        }, 4000);
    }
    if (stepActive === "3") {
        setTimeout(() => {
        setStepActive("4");
        }, 4000);
    }
    if (stepActive === "4") {
        setTimeout(() => {
        setStepActive("5");
        }, 4000);
    }
    if (stepActive === "5") {
        setTimeout(() => {
        setStepActive("1");
        }, 4000);
    }

    // copy the brand section content three time start
        // State to hold the content to be cloned
        const [brandContent, setBrandContent] = useState(
            <div className="brand-slide">
                <img style={{ height: '130px', }} src="/brands/brand1.png" alt="" />
                <img style={{ height: '100px', }} src="/brands/brand2.png" alt="" />
                <img style={{ height: '60px', }} src="/brands/brand3.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand4.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand5.png" alt="" />
                <img style={{ height: '120px', }} src="/brands/brand6.png" alt="" />
                <img style={{ height: '60px', }} src="/brands/brand7.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand8.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand9.png" alt="" />
                <img style={{ height: '40px', }} src="/brands/brand10.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand11.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand12.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand13.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand14.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand15.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand16.png" alt="" />
                <img style={{ height: '60px', }} src="/brands/brand17.png" alt="" />
                <img style={{ height: '80px', }} src="/brands/brand18.png" alt="" />
            </div>
        );
        // Function to handle cloning
        useEffect(() => {
            const copiedContent = React.cloneElement(brandContent);
            setBrandContent(prevContent => (
                <>
                    {prevContent}
                    {copiedContent}
                </>
            ));
        }, []);
    // copy the brand section content three time end

    // testimonial carousel script start
        const breakPoints1 = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 2 },
            { width: 768, itemsToShow: 2 },
            { width: 1200, itemsToShow: 2 },
        ];
        const [currentIndex, setCurrentIndex] = useState(0);
        const carouselRef = useRef(null);
        const items = [
            {
                quote: "Building our dream home with BrickX was an incredible experience. The team was very professional and attentive, and they really listened to our needs. We are extremely happy with our new home and highly recommend BrickX to anyone looking to build a quality, custom home.",
                name: "Mr. Kiran Kumar",
                place: "Miyapur, Hyderabad",
                image: '/user.png',
            },
            {
                quote: "From start to finish, BrickX exceeded our expectations. Their commitment to sustainability and affordability made it possible for us to have a beautiful home within our budget. The craftsmanship is superb, and we couldn't be happier with the result.",
                name: "Mr. Rajesh",
                place: "NGO colony, ongole",
                image: '/user.png',
            },
            {
                quote: "The entire process with BrickX was seamless. They kept us informed at every stage and were always available to answer our queries. The end result is a stunning home that perfectly suits our lifestyle. Thank you, BrickX!",
                name: "Mr. Arjun",
                name2: ", Software Engineer",
                place: "hastinapuram, Hyderabad",
                image: '/user.png',
            },
            {
                quote: "Choosing BrickX was the best decision we made for our new home. Their attention to detail and dedication to quality are evident in every aspect of our house. We are grateful for their hard work and highly recommend them to anyone looking to build.",
                name: "Mrs. praneetha",
                name2: ", SAP consultant",
                place: "pedda amberpet, Hyderabad",
                image: '/user.png',
            },
            {
                quote: "BrickX made our dream of owning a custom home a reality. Their team was professional, efficient, and always had our best interests in mind. We are thrilled with our new home and appreciate everything BrickX has done for us.",
                name: "Mr. krishnakanth",
                place: "hayathnagar, Hyderabad",
                image: '/user.png',
            },
        ];
        const handleTransitionEnd = () => {
            const { current } = carouselRef;
            if (current) {
                const newIndex = current.getActiveIndex();
                setCurrentIndex(newIndex);
        
                // Manually reset the carousel to the beginning when it reaches the end
                if (newIndex === items.length - 1) {
                    current.goTo(0);
                }
            }
        };
    // testimonial carousel script end

    const [isClicked, setIsClicked] = useState(false);
    const handleIsClickedTrue = () => {
        // setLeftToRightTab(false);
        setIsClicked(true);
    };
    const handleClose = async (e) => {
        setIsClicked(false);
    };

    const [formResponse, setFormResponse] = useState(false);
    const [alertInput, setAlertInput] = useState(false);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [propertyName, setPropertyName] = useState('');
    const [mobile, setMobile] = useState("");
    // Handler to allow only numeric input
    const handleInputChange = (e) => {
        const value = e.target.value;
        let inputValue = e.target.value;
        if (inputValue.length <= 10) {
            // Allow only numeric values
            if (!isNaN(value) && !value.includes('e') && !value.includes('E') && !value.includes('+') && !value.includes('-')) {
                setMobile(value);
            }
        }
    };
    // Prevent default behavior for non-numeric keys
    const handleKeyDown = (e) => {
        if (['e', 'E', '+', '-'].includes(e.key)) {
            e.preventDefault();
        }
    };
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const handleClick = async (e) => {
        e.preventDefault();
        if (fullname === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        }
        if (mobile === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        }
        if (propertyName === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        } else {
            setAlertInput(false); 
            axios
            .post(
                `https://brickx.co.in/consultation.php?name=${fullname}&email=${email}&mobile=${mobile}&property_name=${propertyName}`
            )
            .then((response) => {
                console.log(response);
                // alert(response.data.message);
                toast(
                    "Free Consultation Booked👍",
                    {
                        // toast('🦄 Wow so easy!', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
                setFullname("");
                setEmail("");
                setPropertyName("");
                setMobile("");
                setTimeout(() => {
                    setMenuOpen(!menuOpen);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                setFormResponse("Error submitting form");
            });
        }
    }

    const isFormValid = () => {
        return fullname.length > 0 && mobile.length > 0 && propertyName.length > 0;
    };
    const [city, setCity] = useState('Hyderabad');

    const getPackages = (event) => {
      setCity(event.target.value);
    };




    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <div className={isClicked === true ? "modalBG" : "modalBG none"}>
                <div className="modalWhite">
                    <div className="modalWhite-content">
                        <div className="top-header">
                            <h3>Get In Touch With Us</h3>
                            <button className="top-header-close-btn" onClick={handleClose}>
                                <img src="/close.png" alt="" />
                            </button>
                        </div>
                        <form className="form-view">
                            <div class="mb-3">
                                <label for="fullName" class="form-label">Name*</label>
                                <input
                                    type="text"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="fullName"
                                    name="fullName"
                                    placeholder="Your Full Name"
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                />
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input
                                    type="email"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="email"
                                    name="email"
                                    placeholder="Your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div class="mb-3" style={{ position: 'relative', }}>
                                <label for="mobile" class="form-label">Mobile*</label>
                                <img className="india-img" src="/india.png" alt="" />
                                <div className="line" />
                                <input
                                    type="number"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="mobile"
                                    name="mobile"
                                    placeholder="Your Phone Number"
                                    value={mobile}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="updates">
                                <input type="checkbox" id="check1" />
                                <label htmlFor="check1">Send me update on whatsapp</label>
                            </div>
                            <div class="mb-3">
                                <label for="LocationName" class="form-label">Location Name*</label>
                                <input
                                    type="text"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="LocationName"
                                    name="LocationName"
                                    placeholder="Location Name*"
                                    value={propertyName}
                                    onChange={(e) => setPropertyName(e.target.value)}
                                />
                            </div>
                            <div className="updates">
                                <label htmlFor="check1">*By submitting this form, I agree to the BrickX terms & conditions and privacy policy</label>
                            </div>
                            <button className="form-btn" onClick={(e) => handleClick(e)}>
                                Book Free Consultation
                                <ul>
                                    <li className="next"><span></span></li>
                                </ul>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <Header />

            {/* Second Section */}
            <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                {/* <ol class="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol> */}
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img class="d-block w-100" src="/finalize/home page/home_head.jpeg" alt="First slide" />
    

                    </div>
                    {/* <div class="carousel-item">
                        <img class="d-block w-100" src="/carousel/example-img.png" alt="Second slide" />
                    </div>
                    <div class="carousel-item">
                        <img class="d-block w-100" src="/carousel/example-img.png" alt="Third slide" />
                    </div> */}
                </div>
                {/* <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a> */}
            </div>

            {/* Third Section */}
            <div className="fifth-section">
                <h1 className="section-heading">Services For You</h1>
                <p className="section-subheading">Impeccable craftsmanship driven by extensive expertise.</p>
                <div className="box-container1">
                    <div className="our-services-box">
                        <div className="our-services-box-img">
                            <img src="/finalize/services/design-Build.png" className="box1-img" alt="" />
                        </div>
                        <h3 className="our-services-title">Design-Build</h3>
                        {/* <div className="bg-img">
                            <div className="our-services-content">
                                <h3 className="our-services-title">House  construction</h3>
                                <p className="our-services-subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni doloremque numquam fugit dicta possimus, architecto vitae ab, odio rem culpa eaque minima repellendus sit atque expedita nihil sed vero non.</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="our-services-box">
                        <div className="our-services-box-img">
                            <img src="/finalize/services/project-management.png" className="box1-img" alt="" />
                        </div>
                        <h3 className="our-services-title">Project Management</h3>
                        {/* <div className="bg-img2">
                            <div className="our-services-content">
                                <h3 className="our-services-title">Construction for Bisiness</h3>
                                <p className="our-services-subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni doloremque numquam fugit dicta possimus, architecto vitae ab, odio rem culpa eaque minima repellendus sit atque expedita nihil sed vero non.</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="our-services-box">
                        <div className="our-services-box-img">
                            <img src="/finalize/services/renovation_repairs.png" className="box1-img" alt="" />
                        </div>
                        <h3 className="our-services-title">Renovation and Remodeling</h3>
                        {/* <div className="bg-img3">
                            <div className="our-services-content">
                                <h3 className="our-services-title">Comprehensive Project Management</h3>
                                <p className="our-services-subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni doloremque numquam fugit dicta possimus, architecto vitae ab, odio rem culpa eaque minima repellendus sit atque expedita nihil sed vero non.</p>
                            </div>
                        </div> */}
                    </div>
                    <div className="our-services-box">
                        <div className="our-services-box-img">
                            <img src="/finalize/services/interior-design.png" className="box1-img" alt="" />
                        </div>
                        <h3 className="our-services-title">Interior Fit-Out</h3>
                        {/* <div className="bg-img3">
                            <div className="our-services-content">
                                <h3 className="our-services-title">Comprehensive Project Management</h3>
                                <p className="our-services-subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni doloremque numquam fugit dicta possimus, architecto vitae ab, odio rem culpa eaque minima repellendus sit atque expedita nihil sed vero non.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* Fourth Section */}
            <div className="fourth-section">
                <h2 className="section-heading">How It Work</h2>
                <p className="section-subheading">Experience excellence seamlessly from idea to reality.</p>
                <ul className="HowItWorks_stepsBx__HY4oU">
                    {stepActive === "1" ? (
                        <>
                            <li className="HowItWorks_active__4JMUw">
                            <span>1</span>
                            <label>Consultation</label>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                            <span>1</span>
                            <label>Consultation</label>
                            </li>
                        </>
                    )}
                    {stepActive === "2" ? (
                        <>
                            <li className="HowItWorks_active__4JMUw">
                            <span>2</span>
                            <label>Desgin & Plan</label>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                            <span>2</span>
                            <label>Desgin & Plan</label>
                            </li>
                        </>
                    )}
                    {stepActive === "3" ? (
                        <>
                            <li className="HowItWorks_active__4JMUw">
                            <span>3</span>
                            <label>Permit & Approvals</label>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                            <span>3</span>
                            <label>Permit & Approvals</label>
                            </li>
                        </>
                    )}
                    {stepActive === "4" ? (
                        <>
                            <li className="HowItWorks_active__4JMUw">
                            <span>4</span>
                            <label>Construction</label>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                            <span>4</span>
                            <label>Construction</label>
                            </li>
                        </>
                    )}
                    {stepActive === "5" ? (
                        <>
                            <li className="HowItWorks_active__4JMUw">
                            <span>5</span>
                            <label>Completion & Handover</label>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="">
                            <span>5</span>
                            <label>Completion & Handover</label>
                            </li>
                        </>
                    )}
                </ul>
                <div className="box-container">
                    {stepActive === "1" ? (
                        <>
                            <div className="box">
                                <img src="/finalize/how it works/consultation_300_300.png" alt="Box 4" />
                                <p className="box-paragraph">Embark on your path by expressing your aspirations to us. Our seasoned professionals stand prepared to lend an ear, grasp your unique perspective, and offer customized perspectives. Let's delve into the realms of potential collectively, guaranteeing a robust groundwork for your endeavor.</p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {stepActive === "2" ? (
                        <>
                            <div className="box">
                                <img src="/finalize/how it works/planning_300_300.png" alt="Box 4" />
                                <p className="box-paragraph">Witness your concepts evolve into concrete strategies. Our experienced architects and designers will meticulously draft comprehensive plans, harmonizing innovation with feasibility. The outcome? A blueprint that propels your vision towards fruition.</p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {stepActive === "3" ? (
                        <>
                            <div className="box">
                                <img src="/finalize/how it works/aprooval_300_300.png" alt="Box 4" />
                                <p className="box-paragraph">Expertly navigating the intricate terrain of permits and approvals is our forte. We adeptly manage the documentation and facilitate communication with regulatory bodies, guaranteeing adherence to all stipulations. Your tranquility is paramount to us.</p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {stepActive === "4" ? (
                        <>
                            <div className="box">
                                <img src="/finalize/how it works/construction_300_300.png" alt="Box 4" />
                                <p className="box-paragraph">From the inception to the final embellishments, behold the realization of your aspirations. Our adept craftsmen, fueled by a dedication to perfection, translate designs into tangible structures. Embrace each stage with unwavering assurance.</p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    {stepActive === "5" ? (
                        <>
                            <div className="box">
                                <img src="/finalize/how it works/handover_300_300.png" alt="Box 4" />
                                <p className="box-paragraph">The pinnacle of satisfaction dawns as we deliver your finished project. Our meticulous eye for detail and unwavering commitment to excellence guarantee that your vision isn't just fulfilled but surpassed. Embrace the culmination of your dreams with open arms.</p>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {/* Fifth Section */}
            <div className="third-section">
                <h1 className="section-heading">Why BrickX?</h1>
                <p className="section-subheading">Uninterrupted brilliance from inception to completion</p>
                <div className="box3-container">
                    <div className="box3">
                        <img src="/finalize/why brickX/Creative Home Designs.png" alt="Box 1" />
                        <h3 className="box-title">Creative Home Designs</h3>
                        <p className="box-subtitle">Tailored to match your lifestyle and preferences.</p>
                    </div>
                    <div className="box3">
                        <img src="/finalize/why brickX/Transparent Budgeting.png" alt="Box 2" />
                        <h3 className="box-title">Transparent Budgeting</h3>
                        <p className="box-subtitle"> Final project cost is locked before signing the contract, with no hidden charges.</p>
                    </div>
                    <div className="box3">
                        <img src="/finalize/why brickX/Assured Material Quality.png" alt="Box 3" />
                        <h3 className="box-title">Assured Material Quality</h3>
                        <p className="box-subtitle">Guaranteed use of materials as specified in the agreement.</p>
                    </div>
                    <div className="box3">
                        <img src="/finalize/why brickX/Compliance & Warranty.png" alt="Box 3" />
                        <h3 className="box-title">Compliance & Warranty</h3>
                        <p className="box-subtitle">Adherence to government standards, with up to 10 years warranty.</p>
                    </div>
                    <div className="box3">
                        <img src="/finalize/why brickX/On-Time Delivery.png" alt="Box 3" />
                        <h3 className="box-title">On-Time Delivery</h3>
                        <p className="box-subtitle">Guaranteed delivery on schedule, or BrickX pays a penalty.</p>
                    </div>
                </div>
            </div>

            {/* Sixth Section */}
            <div className="fourth-section">
                <h1 className="section-heading">Turning Your Home Ideas Into Reality</h1>
                <p className="section-subheading">Find the best home construction packages*</p>
                <div className="box2-container">
                    <section className="service-area bnblight" id="service">
                    <div className="centered">
                        <select onChange={getPackages} name="city" id="city" className="form__field form__field--theme">
                        <option value="Hyderabad">Hyderabad</option>
                        <option value="Ongole">Ongole</option>
                        </select>
                    </div>

                    <div id="packages" className={`packages ${city === 'Hyderabad' ? 'visible' : 'hidden'}`}>
                        <div className="package">
                            <div className="package-name">Build package</div>
                            <div className="package-price"><li style={{ display: 'flex', alignItems: 'baseline' }}>₹ 1800 /sq.ft <p style={{ fontSize: '11px', }}>&nbsp;(Incl. GST)</p></li></div>
                        </div>
                        <div className="package">
                            <div className="package-name">Premium Package</div>
                            <div className="package-price"><li style={{ display: 'flex', alignItems: 'baseline' }}>₹ 2050 /sq.ft <p style={{ fontSize: '11px', }}>&nbsp;(Incl. GST)</p></li></div>
                        </div>
                        <div className="package">
                            <div className="package-name">Luxury Package</div>
                            <div className="package-price"><li style={{ display: 'flex', alignItems: 'baseline' }}>₹ 2300 /sq.ft <p style={{ fontSize: '11px', }}>&nbsp;(Incl. GST)</p></li></div>
                        </div>
                    </div>

                    <div id="packages" className={`packages ${city === 'Ongole' ? 'visible' : 'hidden'}`}>
                        <div className="package">
                        <div className="package-name">Build package</div>
                        <div className="package-price"><li>₹ 1800 /sqft (Incl. GST)</li></div>
                        </div>
                        <div className="package">
                        <div className="package-name">Premium Package</div>
                        <div className="package-price"><li>₹ 2200 /sqft (Incl. GST)</li></div>
                        </div>
                        <div className="package">
                        <div className="package-name">Luxury Package</div>
                        <div className="package-price"><li>₹ 2350 /sqft (Incl. GST)</li></div>
                        </div>
                    </div>

                    <div className="button-container">
                        <button className="section-button" onClick={handleIsClickedTrue}>
                        Request a Quote
                        <ul>
                            <li className="next"><span></span></li>
                        </ul>
                        </button>
                        <p className="tnc">*T&C Apply</p>
                    </div>
                    </section>
                </div>
            </div>

            {/* Seventh Section */}
            <div className="seventh-section">
                <h1 className="section-heading">Optimal Brands For You</h1>
                <p className="section-subheading">Experience seamless excellence from concept to creation.</p>
                <div className="brand">
                    {/* <div className="brand-slide">
                        <img src="/brands/3m.svg" alt="" />
                        <img src="/brands/barstool-store.svg" alt="" />
                        <img src="/brands/budweiser.svg" alt="" />
                        <img src="/brands/buzzfeed.svg" alt="" />
                        <img src="/brands/forbes.svg" alt="" />
                        <img src="/brands/macys.svg" alt="" />
                        <img src="/brands/menshealth.svg" alt="" />
                        <img src="/brands/mrbeast.svg" alt="" />
                    </div> */}
                    {brandContent}
                </div>
            </div>

            {/* Eight Section */}
            <div className="eight-section">
                <h1 className="section-heading">What People Are Saying</h1>
                {/* <p className="section-subheading">Subheading</p> */}
                <div className="box-container2">
                    <Carousel1
                        ref={carouselRef}
                        onTransitionEnd={handleTransitionEnd}
                        breakPoints={breakPoints1}
                        pagination={false}
                        showArrows={false}
                        enableSwipe={true}
                        // enableAutoPlay={true}
                        // autoPlaySpeed={3000}
                        onNextStart={true}
                    >
                        {items.map((item, index) => (
                            <Item>
                                <div className="swiper-slide">
                                    <div className="swiper-slide-msg">
                                        <p>{item.quote}</p>
                                    </div>
                                    <div className="swiper-client-data grid grid-two-column">
                                        <figure>
                                            <img src={item.image} alt="" />
                                        </figure>
                                        <div className="client-data-details">
                                            <h1
                                                style={{ display: 'flex', alignItems: 'center', }}
                                            >{item.name}&nbsp;{item.name.length > 1 && <h1 style={{ fontWeight: '500', fontSize: 12, }}>{item.name2}</h1>}</h1>
                                            <p>{item.place}</p>
                                        </div>
                                    </div>
                                </div>
                            </Item>
                        ))}
                    </Carousel1>
                </div>
            </div>

            <Footer/>

            <div class="mob-footer visible-xs">
                {/* <div class="row"> */}
                    <div class="bg1">
                        <a href="tel:917730808808" class="mob-call-btn">+91 7730 808 808</a>
                    </div>
                    <div class="bg2">
                        <a onClick={handleIsClickedTrue} class="mob_enq_click mob-enq-btn" data-event-category="Footer" data-event-action="Click" data-event-name="Enquire Now">Contact Us</a>
                    </div>
                {/* </div> */}
            </div>

        </>
    )
}