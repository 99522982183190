import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaDiscord, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function Footer() {

    let location = useLocation();

    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);
    const [faq4, setFaq4] = useState(false);
    const [faq5, setFaq5] = useState(false);

    const handleFAQ1 = () => {
        if (faq1 === false) {
            setFaq1(true);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ2 = () => {
        if (faq2 === false) {
            setFaq1(false);
            setFaq2(true);
            setFaq3(false);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ3 = () => {
        if (faq3 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(true);
            setFaq4(false);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ4 = () => {
        if (faq4 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(true);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
        }
    };
    const handleFAQ5 = () => {
        if (faq5 === false) {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
            setFaq5(true);
        } else {
            setFaq1(false);
            setFaq2(false);
            setFaq3(false);
            setFaq4(false);
            setFaq5(false);
        }
    };

    return (
        <>
            

            {/* Ninth Section */}
            {location.pathname === "/privacy-policy" || location.pathname === "/terms-and-conditions" || location.pathname === "/company/about-us" ? (
                <></>
            ) : (
                <div className="ninth-section">
                    <h1 className="section-heading">Frequently Asked Questions</h1>
                    {/* <p className="section-subheading">Subheading</p> */}
                    <div className="box-container2">
                    <div className="faq-list">
                        <div
                            className={
                            faq1 === true ? "faq-box faq-box-active" : "faq-box"
                            }
                            // style={{
                            //   height: faq1 === true ? "100%" : "52px",
                            //   overflow: "hidden",
                            // }}
                        >
                            <button className="top" onClick={handleFAQ1} type="button">
                                <div className="d-flex">
                                    {faq1 === true ? (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                        </svg>
                                        </>
                                    ) : (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        </>
                                    )}
                                    <h1>
                                        1. What services does BrickX offer?
                                    </h1>
                                </div>
                            </button>
                            <div className="faq-desc">
                                <p>
                                    BrickX provides comprehensive residential construction services, including initial concept design, detailed planning, project management, and construction. We handle everything from start to finish to ensure your dream home is built to your specifications.
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                faq2 === true ? "faq-box faq-box-active" : "faq-box"
                            }
                            // style={{
                            //   height: faq1 === true ? "100%" : "52px",
                            //   overflow: "hidden",
                            // }}
                        >
                            <button className="top" onClick={handleFAQ2} type="button">
                                <div className="d-flex">
                                    {faq2 === true ? (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                        </svg>
                                        </>
                                    ) : (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        </>
                                    )}
                                    <h1>
                                        2. How does BrickX ensure the quality of its homes?
                                    </h1>
                                </div>
                            </button>
                            <div className="faq-desc">
                                <p>
                                    At BrickX, we prioritize quality at every stage of construction. Our team of experienced professionals uses high-quality materials and adheres to strict building standards. We also conduct thorough inspections throughout the project to ensure superior craftsmanship.
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                faq3 === true ? "faq-box faq-box-active" : "faq-box"
                            }
                            // style={{
                            //   height: faq1 === true ? "100%" : "52px",
                            //   overflow: "hidden",
                            // }}
                        >
                            <button className="top" onClick={handleFAQ3} type="button">
                                <div className="d-flex">
                                    {faq3 === true ? (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                        </svg>
                                        </>
                                    ) : (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        </>
                                    )}
                                    <h1>
                                        3. Can I customize my home design with BrickX?
                                    </h1>
                                </div>
                            </button>
                            <div className="faq-desc">
                                <p>
                                    Absolutely! Customization is one of our core values. We work closely with you to understand your preferences and design a home that reflects your unique style and needs. From floor plans to finishes, we offer a wide range of customization options.
                                </p>
                            </div>
                        </div>
                        <div
                            className={
                                faq4 === true ? "faq-box faq-box-active" : "faq-box"
                            }
                            // style={{
                            //   height: faq1 === true ? "100%" : "52px",
                            //   overflow: "hidden",
                            // }}
                        >
                            <button className="top" onClick={handleFAQ4} type="button">
                                <div className="d-flex">
                                    {faq4 === true ? (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                        </svg>
                                        </>
                                    ) : (
                                        <>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512"
                                        >
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                        </svg>
                                        </>
                                    )}
                                    <h1>
                                        4. What makes BrickX homes sustainable?
                                    </h1>
                                </div>
                            </button>
                            <div className="faq-desc">
                                <p>
                                    Sustainability is a key focus at BrickX. We incorporate eco-friendly materials and energy-efficient systems into our designs to reduce environmental impact. Our homes are built to be durable and energy-efficient, providing long-term benefits for both homeowners and the planet.
                                </p>
                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            )}

            {/* Tenth Section */}
            <footer>
                <div className="row">
                    {/* <div> */}
                        <div className="col">
                            {/* <img src="/footer-logo.png" style={{ width: '350px', height: '110px', }} alt="logo" /> */}
                            <a href="/">
                                <h1
                                    style={{
                                        textAlign: 'center',
                                        // width: '100%',
                                        fontSize: 42,
                                        color: "#ff1a41",
                                        fontWeight: 'bold',
                                        marginTop: '25px',
                                        marginLeft: '-19px',
                                        width: '110%',
                                    }}
                                >BrickX</h1>
                            </a>
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo cumque facilis exercitationem architecto quis unde saepe deleniti tempora? Alias, voluptas!</p> */}
                        </div>
                        <div className="col">
                            <h3>Get in Touch </h3>
                            {/* <p>ITPL Road</p>
                            <p>Whitefield, Banglore</p>
                            <p>Karnataka, Pin 560066, India</p> */}
                            <a href="mailto:info@brickx.co.in">
                                <h6 className="email-id"> <i class="fa-solid fa-envelope"></i> &nbsp;info@brickx.co.in</h6>
                            </a>
                            <a href="tel:917730808808" target="_blank">
                                <h6 style={{ color: '#000', fontSize: '15px', }}><i class="fa-solid fa-phone"></i> +91 7730808808</h6>
                            </a>
                        </div>
                    {/* </div> */}
                    <div className="col">
                        <div className="social-icons">
                            
                            <a href="https://www.facebook.com/brickx.co.in" target="_blank" rel="noopener noreferrer">
                                    <i class="fab fa-facebook-f"></i>
                            </a>

                            <a href="https://www.instagram.com/brickx.co.in/" target="_blank" rel="noopener noreferrer">
                                    <i class="fab fa-instagram"></i>
                            </a>

                            <a href="https://www.linkedin.com/company/brickx-co/" target="_blank" rel="noopener noreferrer">
                                    <i class="fab fa-linkedin"></i>
                            </a>
                            <i class="fab fa-youtube"></i>

                            
                            
                        </div>
                    </div>
                </div>
                <hr />
                <div className="col">
                    <div className="row">
                        <p className="copyright">BrickX © 2024 - All Rights Reserved.</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            className="fff"
                        >
                            <li><a href="/terms-and-conditions" style={{ marginRight: 10, color: '#000', }}>Terms & Condition</a></li>
                            <li><a href="/privacy-policy" style={{ marginRight: 10, color: '#000', }}>Privacy Policy</a></li>
                        </div>
                    </div>
                </div>
                {/* <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: -20,
                        marginBottom: -30,
                    }}
                >
                    <p className="copyright">BrickX © 2024 - All Rights Reserved.</p>
                    
                    <ul
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 20,
                        }}
                    >
                        <li><a href="/terms-and-conditions" class="dropdown-item">Terms & Condition</a></li>
                        <li><a href="/privacy-policy" class="dropdown-item">Privacy Policy</a></li>
                    </ul>
                </div> */}
            </footer>
        </>
    )
}
