import styled from "styled-components";

export default styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // height: 350px;
    width: 100%;
    // background: #fff;
    // padding-top: 4rem;
    border-radius: 15px;
    margin-right: 10px;
    // background-color: #00008B;
    // color: #fff;
    // margin: 0 15px;
    // font-size: 4em;
`;