import React, { useState, useEffect, useRef } from "react";
import Carousel1 from "react-elastic-carousel";
import { NavLink } from "react-router-dom";
import { FaDiscord, FaInstagram, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Header() {

    // header sticky top script start
        const [fix, setFix] = useState(false);
        function setFixed() {
            if (window.scrollY >= 500) {
                setFix(true);
            } else {
                setFix(false);
            }
        }
        window.addEventListener("scroll", setFixed);
    // header sticky top script end
    
    const [isClicked, setIsClicked] = useState(false);
    const [formResponse, setFormResponse] = useState(false);
    const [alertInput, setAlertInput] = useState(false);
    const handleIsClickedTrue = () => {
        // setLeftToRightTab(false);
        setIsClicked(true);
    };
    const handleClose = async (e) => {
        setIsClicked(false);
    };

    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [propertyName, setPropertyName] = useState('');
    const [mobile, setMobile] = useState("");
    // const onChange = (e) => {
    //     setCredentials({ ...credentials, [e.target.name]: e.target.value });
    // };
    // Handler to allow only numeric input
    const handleInputChange = (e) => {
        const value = e.target.value;
        let inputValue = e.target.value;
        if (inputValue.length <= 10) {
            // Allow only numeric values
            if (!isNaN(value) && !value.includes('e') && !value.includes('E') && !value.includes('+') && !value.includes('-')) {
                setMobile(value);
            }
        }
    };
    // Prevent default behavior for non-numeric keys
    const handleKeyDown = (e) => {
        if (['e', 'E', '+', '-'].includes(e.key)) {
        e.preventDefault();
        }
    };

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClick = async (e) => {
        e.preventDefault();
        if (fullname === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        }
        if (mobile === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        }
        if (propertyName === "") {
            setAlertInput(true);
            setTimeout(() => {
                setAlertInput(false);
            }, 1000);
        } else {
            setAlertInput(false); 
            axios
            .post(
                `https://brickx.co.in/consultation.php?name=${fullname}&email=${email}&mobile=${mobile}&property_name=${propertyName}`
            )
            .then((response) => {
                console.log(response);
                // alert(response.data.message);
                toast(
                    "Free Consultation Booked👍",
                    {
                        // toast('🦄 Wow so easy!', {
                        position: "top-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    }
                );
                setFullname("");
                setEmail("");
                setPropertyName("");
                setMobile("");
                setTimeout(() => {
                    setMenuOpen(!menuOpen);
                }, 500);
            })
            .catch((error) => {
                console.error(error);
                setFormResponse("Error submitting form");
            });
        }
    }

    const isFormValid = () => {
        return fullname.length > 0 && mobile.length > 0 && propertyName.length > 0;
    };

    return (
        <>
            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            {/* First Section */}
            <div id="header" className={fix ? "header fixed" : "header"}>
                <div className="header-container">
                    <div className="menu-icon" onClick={toggleMenu}>
                        <img src="/menu.png" alt="Menu Icon" /> {/* You can use any icon */}
                    </div>
                    <div className="left-container">
                        {/* Logo */}
                        <a
                            href="/"
                        >
                            <img src="/logo.jpg" alt="Logo" className="logo" />
                        </a>
                        {/* <span className="logo-text">Company Logo</span> */}
                        {/* Tagline */}
                        {/* <p className="tagline">Your Tagline Goes Here</p> */}
                    </div>
                    <div className={`right-container ${menuOpen ? "open" : ""}`}>
                        {/* Navigation Links */}
                        <button className="close-btn" onClick={toggleMenu}>
                            &times;
                        </button>
                        <a href="/" className="nav-link">Home</a>
                        <a href="/company/about-us" className="nav-link">About Us</a>
                        <a className="nav-link" onClick={handleIsClickedTrue}>Contact Us</a>
                        <a href="https://wa.me/917730808808" target="_blank" className="nav-link">
                            <i class="fa-solid fa-phone"></i> &nbsp;
                            +91 7730808808
                        </a>
                        {/* <li class="nav-link nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                More
                            </a>
                            <ul class="dropdown-menu">
                                <li><a href="/terms-and-conditions" class="dropdown-item">Terms & Condition</a></li>
                                <li><a href="/privacy-policy" class="dropdown-item">Privacy Policy</a></li>
                            </ul>
                        </li> */}
                        <button className="nav-link" onClick={handleIsClickedTrue}>
                            Let's Build
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"/>
                            </svg> */}
                            <ul>
                                <li className="next"><span></span></li>
                            </ul>
                        </button>
                    </div>
                </div>
            </div>

            <div className={isClicked === true ? "modalBG" : "modalBG none"}>
                <div className="modalWhite">
                    <div className="modalWhite-content">
                        <div className="top-header">
                            <h3>Get In Touch With Us</h3>
                            <button className="top-header-close-btn" onClick={handleClose}>
                                <img src="/close.png" alt="" />
                            </button>
                        </div>
                        <form className="form-view">
                            <div class="mb-3">
                                <label for="fullName" class="form-label">Name*</label>
                                <input
                                    type="text"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="fullName"
                                    name="fullName"
                                    placeholder="Your Full Name"
                                    value={fullname}
                                    onChange={(e) => setFullname(e.target.value)}
                                />
                            </div>
                            <div class="mb-3">
                                <label for="email" class="form-label">Email</label>
                                <input
                                    type="email"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="email"
                                    name="email"
                                    placeholder="Your Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div class="mb-3" style={{ position: 'relative', }}>
                                <label for="mobile" class="form-label">Mobile*</label>
                                <img className="india-img" src="/india.png" alt="" />
                                <div className="line" />
                                <input
                                    type="number"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="mobile"
                                    name="mobile"
                                    placeholder="Your Phone Number"
                                    value={mobile}
                                    onChange={handleInputChange}
                                    onKeyDown={handleKeyDown}
                                />
                            </div>
                            <div className="updates">
                                <input type="checkbox" id="check1" />
                                <label htmlFor="check1">Send me update on whatsapp</label>
                            </div>
                            <div class="mb-3">
                                <label for="LocationName" class="form-label">Location Name*</label>
                                <input
                                    type="text"
                                    class={alertInput === true ? "form-control alertInput" : "form-control"}
                                    id="LocationName"
                                    name="LocationName"
                                    placeholder="Location Name*"
                                    value={propertyName}
                                    onChange={(e) => setPropertyName(e.target.value)}
                                />
                            </div>
                            <div className="updates">
                                <label htmlFor="check1">*By submitting this form, I agree to the BrickX terms & conditions and privacy policy</label>
                            </div>
                            <button className="form-btn" onClick={(e) => handleClick(e)}>
                                Book Free Consultation
                                <ul>
                                    <li className="next"><span></span></li>
                                </ul>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
